.homeContainer {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px ;
  margin-bottom: 30px;
  border-bottom: 1px solid rgb(230, 217, 217);
}

.title {
  font-size: 24px;
  @media screen and (min-width: 650px) {
    font-size: 30px;
  }
}

.actions {
  display: flex;
  flex-direction: column;
  gap: 10px;
  @media screen and (min-width: 320px) {
      flex-direction: row;
    }
}

.button {
  padding: 8px 15px;
  border: 1px solid grey;
  border-radius: 5%;
  margin-left: 10px;
  background-color: rgb(230, 217, 217);
  font-weight: 700;

  &:hover {
    background-color: beige;
  }
}

.form {
  flex-grow: 1;
  margin-bottom: 20px;  
}
.cardBoard {
  display: flex;
  flex-direction: column;  
  align-items: center;
  gap: 20px;  

  @media screen and (min-width: 650px) {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: stretch;
  }
}

.footer {
  text-align: center;
  border-top: 1px solid rgb(230, 217, 217);

  padding: 20px;
}

.nodata {
  text-align: center;
  font-size: 24px;  
}
