.modalBackdrop {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);  
}

.modalForm {
  position: sticky;
  margin: 0 auto;
  top: 50vh;
  transform: translate(0, -50%);
  background: #ffffff;
  min-height: 420px;
  display: flex;
  flex-direction: column;
  padding: 25px;
  width: 310px;

  @media screen and (min-width: 650px) {
      width: 420px;
      font-size: 18px;
      min-height: 490px;
      
    }
}

.formTitle {
  font-size: 18px;
  text-align: center;
  margin-bottom: 22px;
  line-height: 1.2em;

  @media screen and (min-width: 650px) {      
      font-size: 24px;  
    }
}

.formField {
  position: relative;
  display: flex;
  justify-content: space-between;
  width: 100%;
  font-size: 14px ;
  @media screen and (min-width: 650px) {
      font-size: 18px;
    }
}

.fieldValue {
  font-size: 14px;

  @media screen and (min-width: 650px) {
    font-size: 18px;
    height: 28px;
  }
}

.errorMessage {
  position: absolute;
  top: 22px;
  color: red;
  font-size: 12px;
  margin: 0 auto;
}

.fields {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  margin-bottom: 30px;
  flex-grow: 1;
}

.buttons {
  display: flex;
  justify-content: space-between;

  // margin: 0 30px;
}

.button {
  padding: 8px 15px;
  border: 1px solid grey;
  border-radius: 5%;
  background-color: rgb(230, 217, 217);
  // font-weight: 700;

  &:hover {
    background-color: beige;
  }

     @media screen and (min-width: 650px) {
       font-size: 18px; 
      padding: 8px 25px;
     }
}


