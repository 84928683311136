@import-normalize; /* bring in normalize.css styles */


html {
  box-sizing: border-box;
  /* width: 100vw; */
  overflow-x: hidden;
  /* background-color: #e7eaf2; */
  scroll-behavior: smooth;
 
}

body {
  height: 100%;
  font-family: 'Poppins', 'Circe', sans-serif;
  text-decoration: none;
  margin: 0;
  padding: 0;
  background: white;
  backdrop-filter: blur(25px);
}

/* @media screen and (min-width: 768px) {
  body {
    background: rgba(255, 255, 255, 0.4);
  }
} */

@media screen and (max-width: 767px) {
  body {
    background: rgba(255, 255, 255, 0.4);
    backdrop-filter: blur(25px);
  }
}

#root {
  height: 100%;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

ul,
ol {
  margin: 0;
  padding: 0;
  list-style: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0;
}

p {
  margin-top: 0;
  margin-bottom: 0;
}

a {
  text-decoration: none;
  display: inline-block;
  color: inherit;
  cursor: pointer;
}

img {
  display: block;
  max-width: 100%;
  height: auto;
}

button {
  text-decoration: none;
  cursor: pointer;
  margin: 0px;
  padding: 0px;
  border: none;
  background: none;
}

/* @import './components//Toast/Toast.module.scss'; */
